import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

const CookieConsent = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (window.location.search.includes("hidecookieconsent")) {
      setVisible(false);
    } else if (!getCookieValue()) {
      setVisible(true);
    }
  }, []);

  const accept = () => {
    document.cookie = "cookie_consent=yes; max-age=31536000; path=/";
    if (window.dataLayer) {
      window.dataLayer.push({ event: "page_change" });
    }
    setVisible(false);
  };

  const decline = () => {
    document.cookie = "cookie_consent=no; max-age=31536000; path=/";
    setVisible(false);
  };

  const getCookieValue = () => {
    let nameEQ = "cookie_consent=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  if (!visible) {
    return null;
  }

  return (
    <div class="bg-footerBg z-30 fixed bottom-0 w-full flex flex-row justify-between items-center px-2 md:px-5 py-5 text-09697 sm:text-10909 text-custom-grey">
      <div>
        Wir verwenden Cookies zur Bereitstellung der Website.{" "}
        <Link to="/datenschutz/" class="underline">
          Datenschutz
        </Link>
      </div>
      <div class="space-x-5 flex flex-col-reverse sm:flex-row">
        <button class="pl-3  mt-5 sm:mt-0" onClick={decline}>
          Ablehnen
        </button>
        <button
          class="rounded-md ml-3 md:ml-0 p-3 bg-primary text-white "
          onClick={accept}
        >
          Akzeptieren
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
