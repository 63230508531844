import React from "react";

import { useStaticQuery, graphql } from "gatsby";

import Header from "../components/Header";
import Footer from "../components/Footer";
import CookieConsent from "../components/cookieconsent";

import "../styles/global.css";

import "@fontsource/rubik/latin-400.css";
import "@fontsource/rubik/latin-600.css";
import "@fontsource/rubik/latin-800.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          contact {
            addressTitle
            openingHours {
              days {
                day
                times {
                  time
                }
              }
              title
            }
            earlyHours {
              times
              title
            }
          }
          footer {
            copyright
          }
          herosection {
            address
            email
            telephone
            fax
          }
          news {
            content
          }
          templateKey
        }
      }
    }
  `);

  const { herosection, news, contact, footer } =
    data.markdownRemark.frontmatter;

  return (
    <section>
      <Header herosection={herosection} content={news.content} />
      <main>{children}</main>
      <Footer
        herosection={herosection}
        content={news.content}
        contact={contact}
        footer={footer}
      />
      <CookieConsent />
    </section>
  );
};

export default Layout;
