import React from "react";
import { Link } from "gatsby";

const MobileMenu = ({ content, toggleVisible, visible }) => {
  return (
    <div class="relative">
      <input
        class="hidden"
        type="checkbox"
        id="menu-btn"
        onClick={toggleVisible}
        onKeyDown={toggleVisible}
      />
      <label
        htmlFor="menu-btn"
        class="transform scale-100 sm:scale-125 cursor-pointer float-right pl-0 h-25p w-25p select-none flex flex-col justify-around"
      >
        <span
          class={`${
            visible
              ? "relative transform rotate-45 top-2 block bg-primary h-0.5 w-full transition-transform duration-200"
              : "relative top-0 block bg-primary h-0.5 w-full transition-transform duration-200"
          }`}
        />
        <span
          class={`${
            visible
              ? "relative bg-white"
              : "relative top-0 block bg-primary h-0.5 w-full transition-all duration-200"
          }`}
        />
        <span
          class={`${
            visible
              ? "relative transform -rotate-45 -top-2 block bg-primary h-0.5 w-full transition-transform duration-200"
              : "relative top-0 block bg-primary h-0.5 w-full transition-transform duration-200"
          }`}
        />
      </label>
      <nav
        class={`${
          visible
            ? "z-20 fixed bg-white top-0 w-full h-[calc(100%-7rem)] mini2:h-[calc(100%-8rem)] lg:h-[calc(100%-10rem)] right-0 transition-all transition-position duration-200 ease-out overflow-hidden mt-28 mini2:mt-32 lg:mt-40"
            : "z-0 fixed bg-white top-0 w-full h-0 right-0 transition-all duration-200 ease-out overflow-hidden mt-28 mini2:mt-32 lg:mt-40"
        }`}
      >
        <div class="h-full mx-auto py-4 sm:py-10 lg:py-12">
          <div class="block h-full">
            <div class="h-full flex flex-col justify-between text-15152 sm:text-24242 text-primary font-bold text-center">
              <Link onClick={toggleVisible} onKeyDown={toggleVisible} to="/">
                Start
              </Link>
              {content !== "" && (
                <Link
                  onClick={toggleVisible}
                  onKeyDown={toggleVisible}
                  to="/#aktuelles"
                >
                  Aktuelles
                </Link>
              )}

              <Link
                onClick={toggleVisible}
                onKeyDown={toggleVisible}
                to="/#leistungen"
              >
                Leistungen
              </Link>
              <Link
                onClick={toggleVisible}
                onKeyDown={toggleVisible}
                to="/#kontakt"
              >
                Kontakt
              </Link>
              <Link
                onClick={toggleVisible}
                onKeyDown={toggleVisible}
                to="/praxisteam"
              >
                Praxisteam
              </Link>
              <Link
                onClick={toggleVisible}
                onKeyDown={toggleVisible}
                to="/infomaterial"
              >
                Infomaterial
              </Link>
              <Link
                onClick={toggleVisible}
                onKeyDown={toggleVisible}
                to="/qualitaetsmanagement"
              >
                Qualitätsmanagement
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default MobileMenu;
