import React from "react";

import { Link } from "gatsby";

import MobileMenu from "../components/mobileMenu";

import { ReactComponent as Logo } from "../img/logo-seutter-flueeck.svg";
import { ReactComponent as Phone } from "../img/phone.svg";

const Header = ({ herosection, content }) => {
  const telephoneLink =
    "tel:+49" + herosection.telephone.replace(/\D/g, "").slice(1);
  const [visible, setVisible] = React.useState(false);

  const toggleVisible = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.body.classList.toggle("w-full");
    document.body.classList.toggle("fixed");
    document.body.classList.toggle("overflow-y-hidden");
    let mitarbeiterButton = document.getElementById("mitarbeiterButton");
    if (mitarbeiterButton) {
      mitarbeiterButton.classList.toggle("hidden");
    }

    setVisible(!visible);
  };

  return (
    <React.Fragment>
      <header class="px-20p sm:px-10" id="start">
        <div class="flex justify-between items-center pt-20p sm:pt-30p pb-35p sm:pb-40p max-w-900p mx-auto">
          <div class="w-25p h-25p">
            <a
              href={telephoneLink}
              class="h-25p flex justify-center text-20p mb-1.5 sm:hidden"
            >
              <Phone class="h-full w-full sm:mr-2" />
            </a>
          </div>
          <Link class="h-20 mini2:h-24 lg:h-32" to="/#Start">
            <Logo
              onClick={() => {
                document.body.classList.remove("w-full");
                document.body.classList.remove("fixed");
                document.body.classList.remove("overflow-y-hidden");
              }}
              class="h-full"
              alt={"Logo Praxis Dres. med. Flüeck und Seutter"}
            />
          </Link>
          <MobileMenu
            content={content}
            toggleVisible={toggleVisible}
            visible={visible}
          />
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
